import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../assets/images/privacy-policy.jpg'
import avisoAgn from '../assets/images/AvisoPrivacidadAppAGN.pdf'

const PrivacyPolicy = () => {
    return (
        <Layout
            titulo="Aviso de privacidad"
            descripcion="Aviso de privacidad"
            slug="privacy-policy"
            imagen="https://res.cloudinary.com/dev-empty/image/upload/v1630907493/project_start1_fcd74cc421.png"
        >
            <Navbar />
            
            <PageBanner
                pageTitle="Aviso de Privacidad" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Aviso de Privacidad" 
            />

            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <p><i>Este aviso de privacidad fue actualizado por última vez el 12 de diciembre de 2022.</i></p>
                                <h2>AVISO DE PRIVACIDAD PARA LA APP AGN</h2>
                                <a href={avisoAgn}>Verlo aquí</a>
                            </div>
                            <div className="privacy-policy-content">
                                <img src={privacyPolicy} alt="pp" />
                                <p><i>Este aviso de privacidad fue actualizado por última vez el 7 de julio de 2021.</i></p>
                                <h2>AVISO DE PRIVACIDAD</h2>
                                DOMAIN DIFFERENTECH SA DE CV, mejor conocido como DO&#123;MAIN&#125;, con domicilio en calle Prolongación Vasco de Quiroga #3735, colonia El Yaqui, ciudad CDMX, municipio o delegación Cuajimalpa, c.p. 05320, en la entidad de CDMX, país México, y portal de internet domainmx.mx, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
                                <h3>1. ¿Para qué fines utilizaremos sus datos personales?</h3>
                                <blockquote className="blockquote">
                                    <p>
                                    Utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
                                    </p>
                                </blockquote>
                                <p>● para tener contacto con el personal del cliente relacionado con el proyecto</p>
                                <p>En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, indíquelo a continuación:</p>
                                <p>No consiento que mis datos personales se utilicen para los siguientes fines:</p>
                                <p>[ ] para tener contacto con el personal del cliente relacionado con el proyecto</p>
                                <p>La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>
                                <h3>2. ¿Qué datos personales utilizaremos para estos fines?</h3>
                                <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
                                ● Nombre<br/>
                                ● Teléfono celular<br/>
                                ● Puesto o cargo que desempeña <br/>
                                ● Correo electrónico institucional <br/>
                                ● Teléfono institucional<br/>
                                ● Datos de identificación<br/>
                                ● Datos de contacto<br/>
                                <h3>3. ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</h3>
                                <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
                                Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:<br/><br/>
                                Contacto directo a nuestro correo arco@domainmx.mx<br/><br/>
                                Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:<br/><br/>
                                a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?<br/>
                                Identificación oficial<br/><br/>
                                b) ¿Qué información y/o documentación deberá contener la solicitud? Datos que desea modificar o rechazar, nombre y fecha<br/><br/>
                                c) ¿En cuántos días le daremos respuesta a su solicitud? 5<br/><br/>
                                d) ¿Por qué medio le comunicaremos la respuesta a su solicitud? correo electrónico<br/><br/>
                                e) ¿En qué medios se pueden reproducir los datos personales que, en su caso, solicite? listas internas de correo<br/><br/>
                                f) Ponemos a sus órdenes los siguientes formularios o sistemas para facilitar el ejercicio de derechos ARCO: https://domainmx.mx<br/><br/>
                                g) Para mayor información sobre el procedimiento, ponemos a disposición los siguientes medios: https://domainmx.mx<br/><br/>
                                Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:<br/><br/>
                                a) Nombre de la persona o departamento de datos personales: Darío Vasconcelos<br/>
                                b) Domicilio: calle Prolongación Vasco de Quiroga #3735 TH 9C, colonia el Yaqui, ciudad CDMX, municipio o delegación Cuajimalpa, c.p. 05320, en la entidad de CDMX, país México<br/>
                                c) Correo electrónico: dario@domainmx.mx<br/>
                                d) Número telefónico: 5552523629<br/>
                                </p>
                                <h3>4. Usted puede revocar su consentimiento para el uso de sus datos personales</h3>
                                Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.<br/>
                                Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio:<br/><br/>
                                Nuestro sitio web<br/><br/>
                                Con relación al procedimiento y requisitos para la revocación de su consentimiento, le informamos lo siguiente:<br/><br/>
                                a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?<br/>
                                identificación oficial<br/><br/>
                                b) ¿Qué información y/o documentación deberá contener la solicitud?<br/>Datos que desea modificar o rechazar, fecha y lugar<br/>
                                c) ¿En cuántos días le daremos respuesta a su solicitud?<br/>
                                5<br/><br/>
                                d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?<br/>correo electrónico<br/>

                                <h3>5. El uso de tecnologías de rastreo en nuestro portal de internet</h3>
                                <p>
                                Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines:<br/><br/>
                                Monitoreo y obtención de métricas de visitas<br/><br/>
                                Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:<br/><br/>
                                Tipo de navegador del usuario<br/>
                                Tipo de sistema operativo del usuario<br/>
                                Fecha y hora del inicio y final de una sesión de un usuario<br/> Páginas web visitadas por un usuario<br/><br/>
                                Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos:<br/><br/> Desactivando las cookies en su navegador.<br/>
                                </p>
                                <h3>6. ¿Cómo puede conocer los cambios en este aviso de privacidad?</h3>
                                <p>
                                El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.<br/>
                                Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: Página web.<br/>
                                El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:<br/>
                                Actualización del aviso de privacidad en el sitio web y envío de correo electrónico<br/>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                Nosotros
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contáctanos
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/privacy-policy">
                                                Aviso de Privacidad
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/politica-cookies">
                                                Uso de Cookies
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;